import { Route } from '../models/router/route';

type Routes = Record<string, Route>;

export const ROUTES: Routes = {
  HOME: { path: '/', name: 'Acasa' },
  BLOG: { path: '/blog', name: 'Blog' },
  ARTICLE: { path: '/article/:documentId', name: 'Articol' },
  SUPPORT: { path: '/suport', name: 'Suport' },
} as const;
