import { adminApi } from '@sgde/core';
import { BlogItem } from '../models/dto/blog/blogItem';
import { Response } from '../models/dto/response';
import { ResponseList } from '../models/dto/responseList';

const blogApi = adminApi.enhanceEndpoints({ addTagTypes: ['BlogList', 'BlogItem'] }).injectEndpoints({
  endpoints: build => ({
    getArticles: build.query<ResponseList<BlogItem>, void>({
      query: () => '/api/blog-items?populate=*&sort=id:desc',
      providesTags: ['BlogList'],
    }),
    getArticle: build.query<Response<BlogItem>, string>({
      query: documentId => `/api/blog-items/${documentId}/?populate=*`,
      providesTags: ['BlogItem'],
    }),
  }),
});

export const { useGetArticleQuery: useArticle, useGetArticlesQuery: useArticles } = blogApi;
